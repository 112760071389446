










import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({})
export default class GoBackButton extends Vue {

  @Prop({ type: String }) title!: string

  @Emit()
  goBack() {
    return null
  }

  get buttonTitle() {
    return this.title ?? this.$t('back_button').toString()
  }
}
