import getUsers, { getTariff, getUserPointsPlaceholders } from '@/includes/placeholders/getUsers'

import {
  PlaceholdersStack,
  PlaceholdersType
} from 'piramis-base-components/src/components/Editor/components/Toolbar/types'

import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { cloneDeep } from "lodash";
import i18n from "@/i18n/i18n";

@Component
export default class Placeholders extends Vue {

  get messageCountPlaceholders() {
    return {
      buttonTitle: this.$t('message_editor_placeholders_messages_count').toString(),
      placeholders: [
        {
          label: this.$t('message_editor_placeholders_message_count_info_prev_day').toString(),
          value: '%msg_prev_day_1%',
          tags: [ ...getTariff('extra_placeholders') ]
        },
        {
          label: this.$t('message_editor_placeholders_message_count_info_prev_days').toString(),
          value: '%msg_prev_days_1%',
          tags: [ ...getTariff('extra_placeholders') ]
        },
        {
          label: this.$t('message_editor_placeholders_msg_today').toString(),
          value: '%msg_today%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_msg_week').toString(),
          value: '%msg_week%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_msg_month').toString(),
          value: '%msg_month%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_msg_1day').toString(),
          value: '%msg_1day%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_msg_7days').toString(),
          value: '%msg_7days%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_msg_28days').toString(),
          value: '%msg_28days%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_msg_avghour').toString(),
          value: '%msg_avghour%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
      ]
    }
  }

  get activeUsersPlaceholders() {
    return {
      buttonTitle: this.$t('message_editor_placeholders_active_users').toString(),
      placeholders: [
        {
          label: this.$t('message_editor_placeholders_users_today').toString(),
          value: '%users_today%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_users_week').toString(),
          value: '%users_week%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_users_month').toString(),
          value: '%users_month%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_users_1day').toString(),
          value: '%users_1day%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_users_7days').toString(),
          value: '%users_7days%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_users_28days').toString(),
          value: '%users_28days%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
        {
          label: this.$t('message_editor_placeholders_users_avghour').toString(),
          value: '%users_avghour%',
          tags: [ ...getTariff('extra_placeholders') ],
        },
      ]
    }
  }

  get messageInfoPlaceholders() {
    return {
      buttonTitle: this.$t('message_editor_placeholders_message_info').toString(),
      placeholders: [
          ...this.$store.getters.isChatForum ? [
        {
          label: this.$t('message_editor_placeholders_message_thread_link').toString(),
          value: '%message_thread_link%'
        },
        {
          label: this.$t('message_editor_placeholders_reply_message_thread_link').toString(),
          value: '%reply_message_thread_link%'
        },
        ] : [],
        {
          label: this.$t('message_editor_placeholders_message_info_text').toString(),
          value: '%message%'
        },
        {
          label: this.$t('message_editor_placeholders_message_info_reply_text').toString(),
          value: '%reply_message%'
        },
        {
          label: this.$t('message_editor_placeholders_message_info_id').toString(),
          value: '%message_id%'
        },
        {
          label: this.$t('message_editor_placeholders_message_info_reply_id').toString(),
          value: '%reply_message_id%'
        },
        {
          label: this.$t('message_editor_placeholders_message_info_link').toString(),
          value: '%message_link%'
        },
        {
          label: this.$t('message_editor_placeholders_message_info_reply_link').toString(),
          value: '%reply_message_link%'
        }
      ]
    }
  }

  get chatInfoPlaceholders() {
    return {
      buttonTitle: this.$t('message_editor_placeholders_chat_info').toString(),
      placeholders: [
        {
          label: this.$t('message_editor_placeholders_chat_info_chat_id').toString(),
          value: '%chat_id%'
        },
        {
          label: this.$t('message_editor_placeholders_chat_info_chat_title').toString(),
          value: '%chat_title%'
        },
        {
          label: this.$t('message_editor_placeholders_chat_info_chat_username').toString(),
          value: '%chat_login%'
        },
        {
          label: this.$t('message_editor_placeholders_chat_info_chat_reputation_url').toString(),
          value: '%reputation_url%'
        },
        {
          label: this.$t('message_editor_placeholders_referrals_url').toString(),
          value: '%referrals_url%'
        },
        {
          label: this.$t('message_editor_placeholders_referrals').toString(),
          value: '%referrals%'
        },
        {
          label: this.$t('message_editor_placeholders_referrals_full_url').toString(),
          value: '%referrals_full_url%'
        },
        {
          label: this.$t('message_editor_placeholders_active_referrals_url').toString(),
          value: '%active_referrals_url%'
        },
        {
          label: this.$t('message_editor_placeholders_active_referrals_full_url').toString(),
          value: '%active_referrals_full_url%'
        }
      ]
    }
  }

  get randUsersPlaceholders() {
    return {
      buttonTitle: this.$t('message_editor_placeholders_rand_users').toString(),
      description: this.$t('message_editor_placeholders_rand_users_description').toString(),
      placeholders: [
        {
          label: this.$t('message_editor_placeholders_rand_user').toString(),
          value: '%rnd_user_username_1%',
          tags: [ ...getTariff('extra_placeholders') ]
        },
        {
          label: this.$t('message_editor_placeholders_rand_active_user').toString(),
          value: '%rnd_active_user_username_1%',
          tags: [ ...getTariff('extra_placeholders') ]
        },
      ]
    }
  }

  get buttonTitleOtherPlaceholders() {
    return {
      buttonTitle: this.$t('message_editor_placeholders_button_title_other').toString(),
      placeholders: [
        {
          label: this.$t('message_editor_placeholders_timestamp').toString(),
          value: '%timestamp%'
        },
        {
          label: this.$t('message_editor_placeholders_timestamp_ms').toString(),
          value: '%timestamp_ms%'
        },
        {
          label: this.$t('message_editor_placeholders_rand').toString(),
          value: '%rand%'
        },
        {
          label: this.$t('message_editor_placeholders_rand_10').toString(),
          value: '%rand10%'
        },
        {
          label: this.$t('message_editor_placeholders_rand_100').toString(),
          value: '%rand100%'
        },
        {
          label: this.$t('message_editor_placeholders_rand_1000').toString(),
          value: '%rand1000%'
        },
        {
          label: this.$t('message_editor_placeholders_guid').toString(),
          value: '%guid%'
        },
        {
          label: this.$t('message_editor_placeholders_date').toString(),
          value: '%date%'
        },
        {
          label: this.$t('message_editor_placeholders_time').toString(),
          value: '%time%'
        },
        {
          label: this.$t('message_editor_placeholders_datetime').toString(),
          value: '%datetime%'
        },
        {
          label: this.$t('message_editor_placeholders_hdate').toString(),
          value: '%hdate%'
        },
        {
          label: this.$t('message_editor_placeholders_htime').toString(),
          value: '%htime%'
        },
        {
          label: this.$t('message_editor_placeholders_hdatetime').toString(),
          value: '%hdatetime%'
        },
        {
          label: this.$t('message_editor_placeholders_adminscount').toString(),
          value: '%admins_count%'
        },
        {
          label: this.$t('message_editor_placeholders_achievements').toString(),
          value: '%achievements%'
        },
      ]
    }
  }

  get PUNISH_MESSAGE_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_punish_action_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get BASE_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('main_placeholders_title').toString(),
          sections: [
            this.messageInfoPlaceholders,
            this.chatInfoPlaceholders,
            this.randUsersPlaceholders,
            this.messageCountPlaceholders,
            this.activeUsersPlaceholders,
            this.buttonTitleOtherPlaceholders
          ]
        } ]
    }
  }

  get WARNING_COUNT_PLACEHOLDERS() {
    return [
      {
        label: this.$t('message_editor_placeholders_face_control_total_warns_count').toString(),
        value: '%total_warning_count%'
      },
      {
        label: this.$t('message_editor_placeholders_face_control_warns_count').toString(),
        value: '%warning_count%',
        description: this.$t('message_editor_placeholders_face_control_warn_description').toString()
      },
      {
        label: this.$t('message_editor_placeholders_face_control_max_warns_count').toString(),
        value: '%max_warning_count%',
        description: this.$t('message_editor_placeholders_face_control_max_warns_count_description').toString(),
      }
    ]
  }

  get FACE_CONTROL_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [ {
        sectionTitle: this.$t('special_placeholders_title').toString(),
        sections: [ {
          buttonTitle: this.$t('message_editor_placeholders_face_control_target').toString(),
          placeholders: [ ...getUsers('target') ]
        },
          {
            buttonTitle: this.$t('message_editor_placeholders_face_control_warns').toString(),
            placeholders: this.WARNING_COUNT_PLACEHOLDERS
          }
        ],
      },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get FILTERS_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_filters_target').toString(),
              placeholders: [
                ...getUsers('target'),
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_bans').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_ban_level').toString(),
                  value: '%banlevel%',
                  description: this.$t('message_editor_placeholders_ban_level_description').toString()
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_str').toString(),
                  value: '%bantime_str%',
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_to_hr').toString(),
                  value: '%bantime_to_hr%'
                },
                {
                  label: this.$t('message_editor_placeholders_ban_time_end').toString(),
                  description: this.$t('message_editor_placeholders_ban_time_end_description').toString(),
                  value: '%bantime_end%'
                },
              ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_face_control_warns').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_face_control_total_warns_count').toString(),
                  value: '%total_warning_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_face_control_warns_count').toString(),
                  value: '%warning_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_face_control_max_warns_count').toString(),
                  value: '%max_warning_count%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get FORM_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [ {
        sectionTitle: this.$t('special_placeholders_title').toString(),
        sections: [
          {
            buttonTitle: this.$t('message_editor_placeholders_forms').toString(),
            placeholders: [
              {
                label: this.$t('message_editor_placeholders_forms_title').toString(),
                value: '%form_name%',
              },
              {
                label: this.$t('message_editor_placeholders_forms_description').toString(),
                value: '%form_description%',
              },
              {
                label: this.$t('message_editor_placeholders_forms_open_count').toString(),
                value: '%form_open_count%',
              },
              {
                label: this.$t('message_editor_placeholders_forms_responce_count').toString(),
                value: '%form_responce_count%',
              },
            ]
          },
          {
            buttonTitle: this.$t('form_placeholders_users_target').toString(),
            placeholders: [ ...getUsers('target') ]
          }
        ]
      },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get GREETING_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_greeting_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_greeting_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_captcha').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_captcha_numbers').toString(),
                  value: '%captcha%'
                },
                {
                  label: this.$t('message_editor_placeholders_captcha_words').toString(),
                  value: '%captcha_txt%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get CHAT_BUTTONS_MESSAGE_PLACEHOLDERS() {
    const basePlaceholders = cloneDeep(this.BASE_PLACEHOLDERS)

    basePlaceholders.placeholders.forEach(p => {
      p.sections = p.sections.filter(p => p.buttonTitle !== this.$t('message_editor_placeholders_message_info').toString())
    })

    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_greeting_target').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
          ]
        },
        ...basePlaceholders.placeholders
      ]
    }

  }

  get BANTIME_PLACEHOLDERS() {
    return [
      {
        label: this.$t('message_editor_placeholders_ban_time_str').toString(),
        description: this.$t('message_editor_placeholders_ban_time_str_description').toString(),
        value: '%bantime_str%',
      },
      {
        label: this.$t('message_editor_placeholders_ban_time_to_hr').toString(),
        value: '%bantime_to_hr%'
      },
      {
        label: this.$t('message_editor_placeholders_ban_reason').toString(),
        value: '%banreason%'
      },
      {
        label: this.$t('message_editor_placeholders_banreason_or_empty').toString(),
        description: this.$t('message_editor_placeholders_banreason_or_empty_description').toString(),
        value: '%banreason_or_empty%'
      },
      {
        label: this.$t('message_editor_placeholders_ban_time_end').toString(),
        description: this.$t('message_editor_placeholders_ban_time_end_description').toString(),
        value: '%bantime_end%'
      },
    ]
  }

  get MODERATION_COMMANDS_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_warn_system_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_warn_system_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_time_reason').toString(),
              placeholders: this.BANTIME_PLACEHOLDERS
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get RANK_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_user').toString(),
              placeholders: [
                ...getUsers('target'),
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_in_chat').toString(),
                  value: '%target_days_in_chat%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_from_first_message').toString(),
                  value: '%target_days_from_first_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_with_message').toString(),
                  value: '%target_days_with_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_messages_count').toString(),
                  value: '%target_msg_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp').toString(),
                  value: '%target_xp%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp_next_rank').toString(),
                  value: '%target_xp_next_rank%'
                }
              ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get ACTIVITY_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_activity_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders,
      ]
    }
  }

  get REFERRAL_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_referral_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_referral_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_button_title_other').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_join_chat_link').toString(),
                  value: '%join_chat_link%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders,
      ]
    }
  }

  get SPEC_REP_TRIG_DEF_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_spec_rep_trig_actor').toString(),
              placeholders: [ ...getUsers('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_spec_rep_trig_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get SPEC_REP_TRIG_OVR_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            ...this.SPEC_REP_TRIG_DEF_PLACEHOLDERS.placeholders[0].sections,
            {
              buttonTitle: this.$t('message_editor_placeholders_spec_rep_trig_overtake').toString(),
              placeholders: [ ...getUsers('overtake') ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get TRIGGERS_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_triggers_actor').toString(),
              placeholders: [ ...getUsers('actor'), ...getUserPointsPlaceholders('actor') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_triggers_target').toString(),
              placeholders: [ ...getUsers('target'), ...getUserPointsPlaceholders('target') ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }

  get WARN_SYSTEM_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [ {
            buttonTitle: this.$t('message_editor_placeholders_warn_system_actor').toString(),
            placeholders: [ ...getUsers('actor') ]
          },
            {
              buttonTitle: this.$t('message_editor_placeholders_warn_system_target').toString(),
              placeholders: [ ...getUsers('target') ]
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_time_reason').toString(),
              placeholders: this.BANTIME_PLACEHOLDERS
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_face_control_warns').toString(),
              placeholders: this.WARNING_COUNT_PLACEHOLDERS
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders,
      ]
    }
  }

  get XP_PLACEHOLDERS(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_xp_target').toString(),
              placeholders: [
                ...getUsers('target'),
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_in_chat').toString(),
                  value: '%target_days_in_chat%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_from_first_message').toString(),
                  value: '%target_days_from_first_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_days_with_message').toString(),
                  value: '%target_days_with_message%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_messages_count').toString(),
                  value: '%target_msg_count%'
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp').toString(),
                  value: '%target_xp%'
                },
                {
                  label: this.$t('message_editor_placeholders_assigned_xp').toString(),
                  value: '%target_assigned_xp%',
                  // tags: [ ...getTariff('extra_placeholders') ]
                },
                {
                  label: this.$t('message_editor_placeholders_rank_info_xp_next_rank').toString(),
                  value: '%target_xp_next_rank%'
                },
              ],
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_xp_count').toString(),
              placeholders: [
                {
                  label: this.$t('message_editor_placeholders_assigned_xp').toString(),
                  value: '%target_assigned_xp%'
                }
              ]
            }
          ]
        },
        ...this.BASE_PLACEHOLDERS.placeholders,
      ]
    }
  }

  get votebanMessagePlaceholders(): PlaceholdersStack {
    return {
      type: PlaceholdersType.Stack,
      placeholders: [
        {
          sectionTitle: this.$t('special_placeholders_title').toString(),
          sections: [
            {
              buttonTitle: this.$t('message_editor_placeholders_voteban_actor').toString(),
              placeholders: [
                {
                  label: i18n.t('message_editor_placeholders_user_username').toString(),
                  value: `%actor_username%`,
                },
              ],
            },
            {
              buttonTitle: this.$t('message_editor_placeholders_voteban_target').toString(),
              placeholders: [
                {
                  label: i18n.t('message_editor_placeholders_user_username').toString(),
                  value: `%target_username%`,
                },
              ],
            },
          ]
        },
          ...this.BASE_PLACEHOLDERS.placeholders
      ]
    }
  }
}
