import { invert } from "lodash";

export enum WeekDaysEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const WeekDaysIndexMap: Record<number, WeekDaysEnum> = {
  1: WeekDaysEnum.MONDAY,
  2: WeekDaysEnum.TUESDAY,
  3: WeekDaysEnum.WEDNESDAY,
  4: WeekDaysEnum.THURSDAY,
  5: WeekDaysEnum.FRIDAY,
  6: WeekDaysEnum.SATURDAY,
  7: WeekDaysEnum.SUNDAY,
}

export const InvertedWeekDaysIndexMap = invert(WeekDaysIndexMap) as unknown as Record<WeekDaysEnum, string>
